<template>
  <div class="funds-container">
    <v-card elevation="0" class="funds-wrapper">
      <v-row no-gutters class="section-container" v-if="isRequiredVerification && !memberPhoneVerified">
        <div class="d-flex align-center">
          <v-img src="/static/image/profile/personal-info-warning.svg" width="25" height="25" :contain="true"></v-img>
          <label class="px-2">{{ $t(`label.phone_deposit_not_verify`) }}</label>
          <label class="link-underscore warning-text" @click="this.RedirectToProfilePage">{{ $t(`button.verify`) }}</label>
        </div>
      </v-row>
      <!--<v-stepper v-model="localBankDepositStep" v-if="transactionObj.methodCode == 'LB'">
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-form ref="localBankStepOneTransactionForm">
              <v-col class="section-container no-gutters">
                <div class="funds-title-wrapper">
                  <label class="text-capitalize subtitle-1 funds-title">{{ $t(`field.paymentType`) }}</label>
                </div>
                <div class="funds-divider"></div>
                <v-row no-gutters class="button-container">
                  <v-col cols="auto" v-for="(method, index) in availableMethods" :key="index">
                    <v-btn
                      :value="method.code"
                      :input-value="transactionObj.methodCode == method.code"
                      :class="'gateway-button'"
                      :active-class="'active-gateway-button'"
                      @click="changeDepositMethod(method)"
                      outlined
                      height="auto"
                    >
                      <img v-if="method.code == 'NLB'" :src="`/static/image/icon/local-bank.png`" />
                      <img v-else :src="`/static/svg/deposit-ewallet-${method.code.toLowerCase()}.svg`" />
                      <label>{{ method.name }}</label>
                      <div class="tag-recommond"></div>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <div class="section-container">
                <div class="funds-title-wrapper">
                  <label class="text-capitalize subtitle-1 funds-title">{{ $t(`label.chooseLocalBank`) }}</label>
                </div>
                <div class="funds-divider"></div>
                <v-col cols="12" md="5" class="pa-0">
                  <app-form-field
                    v-model.trim="transactionObj.merchantBankCode"
                    :enabledLabel="false"
                    :label="$t(`field.depositingBank`)"
                    type="select"
                    :rules="validator.dropDownListRules()"
                    :items="merchantBankAccounts"
                    :displayText="this.getMerchantBankAccountsText"
                    customClass="depositField"
                  ></app-form-field>
                </v-col>
              </div>
              <div class="section-container">
                <div class="funds-title-wrapper">
                  <label class="funds-title">{{ $t(`label.amount`) }}</label>
                </div>
                <div class="funds-divider"></div>
                <v-row no-gutters class="button-container">
                  <v-btn v-for="i in depositAmounts" :key="i" height="auto" width="76" @click="applyDepositAmount(i)" class="gateway-button" :active-class="'active-gateway-button'" outlined>
                    {{ i }}
                  </v-btn>
                </v-row>
                <v-text-field
                  :placeholder="
                    selectedMethod != ''
                      ? stringFormat($t(`fieldHint.minMax`), currencyFormatter(selectedMethod.minAmount, transactionObj.currency), currencyFormatter(selectedMethod.maxAmount, transactionObj.currency))
                      : ''
                  "
                  :enabledLabel="false"
                  class="funds-input"
                  clearable
                  type="number"
                  v-model="transactionObj.amount"
                  :change="this.updateMoneyAmount"
                  :keyup="this.checkDepostAmount"
                  :rules="validator.depositAmountRules(2, selectedMethod != '' ? selectedMethod.minAmount : 100, selectedMethod != '' ? selectedMethod.maxAmount : 10000, transactionObj.currency)"
                ></v-text-field>
              </div>
              <v-form ref="bonusForm">
                <v-row no-gutters class="section-container">
                  <div class="funds-title-wrapper">
                    <label class="funds-title">{{ $t(`field.depositBonus`) }}</label>
                  </div>
                  <div class="funds-divider"></div>
                  <v-col cols="12" md="5" v-if="bonusList.length > 0">
                    <app-form-field
                      :enabledLabel="false"
                      :label="$t(`field.depositBonus`)"
                      v-model.trim="bonusObj.id"
                      type="select"
                      :items="bonusList"
                      :displayText="this.getBonusDisplayText"
                      :change="this.checkDepositAmount"
                      :rules="bonusRequiredDepositAmount != '' ? validator.bonusRequiredDepositAmountRules(bonusRequiredDepositAmount, transactionObj.amount, transactionObj.currency) : []"
                      :clearable="true"
                    ></app-form-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-form>
            <v-row no-gutters class="pa-3">
              <app-button customClass="next-button" :title="$t(`button.next`)" :action="this.proceedToMemberDepositDetailTab"></app-button>
            </v-row>
          </v-stepper-content>
          <v-stepper-content step="2" class="px-0">
            <v-form ref="transactionForm">
              <div
                class="credit-card-wrapper"
                v-if="transactionObj.merchantBankCode && transactionObj.merchantBankCode != 0 && merchantBankAccounts.find(x => x.value == transactionObj.merchantBankCode)"
              >
                <div class="credit-card">
                  <v-row>
                    <v-col cols="2" class="align-self-center">
                      <v-img src="/static/image/credit-card/icon-bank.svg" :contain="true" width="25" height="25"></v-img>
                    </v-col>
                    <v-col cols="9" class="mt-1">
                      <v-row class="py-1">
                        <div>{{ merchantBankAccounts.find(x => x.value == transactionObj.merchantBankCode).accountName }}</div>
                        <div class="align-self-center ml-2">
                          <a @click="copyClipboard(merchantBankAccounts.find(x => x.value == transactionObj.merchantBankCode).accountName)">
                            <v-img src="/static/image/credit-card/icon-copy.svg" :contain="true" width="16" height="16"></v-img>
                          </a>
                        </div>
                      </v-row>
                      &lt;!&ndash; <v-row class="py-1">
                                            <div>{{merchantBankAccounts.find(x => x.value == transactionObj.merchantBankCode).accountName}}</div>
                                            <div class="align-self-center ml-2">
                                                <a><v-img src="/static/image/credit-card/icon-copy.svg" :contain="true" width="16" height="16"></v-img></a>
                                            </div>
                                        </v-row> &ndash;&gt;
                    </v-col>
                    <v-col cols="1">
                      <v-img class="chip" src="/static/image/credit-card/chip.png" width="32" height="32"></v-img>
                    </v-col>
                  </v-row>
                  <v-col class="mt-5 mx-3">
                    <v-row class="credit-card-number">
                      {{ merchantBankAccounts.find(x => x.value == transactionObj.merchantBankCode).accountNumber }}
                      <div class="align-self-center ml-2">
                        <a @click="copyClipboard(merchantBankAccounts.find(x => x.value == transactionObj.merchantBankCode).accountNumber)">
                          <v-img src="/static/image/credit-card/icon-copy.svg" :contain="true" width="16" height="16"></v-img>
                        </a>
                      </div>
                    </v-row>
                    <v-row class="mt-3">
                      <div>{{ merchantBankAccounts.find(x => x.value == transactionObj.merchantBankCode).accountName }}</div>
                      <div class="align-self-center ml-2">
                        <a @click="copyClipboard(merchantBankAccounts.find(x => x.value == transactionObj.merchantBankCode).accountName)">
                          <v-img src="/static/image/credit-card/icon-copy.svg" :contain="true" width="16" height="16"></v-img>
                        </a>
                      </div>
                    </v-row>
                  </v-col>
                  <v-row class="mt-3">
                    <label style="line-height: 2">{{ $t(`label.amount`) }}</label>
                    <label class="ml-3 credit-card-amount">{{ transactionObj.amount }}</label>
                  </v-row>
                </div>
              </div>
              <div class="section-container">
                <v-col cols="12" class="pa-0 no-gutters">
                  <app-form-field
                    v-model="merchantBankAccounts.find(x => x.value == transactionObj.merchantBankCode).accountName"
                    :label="$t(`field.depositingBankAccountName`)"
                    :readonly="true"
                  ></app-form-field>
                  <app-form-field
                    v-model="merchantBankAccounts.find(x => x.value == transactionObj.merchantBankCode).accountNumber"
                    :enabledLabel="false"
                    :label="$t(`field.depositingBankAccountNumber`)"
                    :readonly="transactionObj.merchantBankCode != 0"
                  ></app-form-field>
                  <div v-if="transactionObj.merchantBankCode && transactionObj.merchantBankCode == 0">
                    <app-form-field
                      v-model.trim="selectedBankCodeValue"
                      :items="banks.filter(x => x.value != 0)"
                      :enabledLabel="false"
                      :label="$t(`field.bank`)"
                      :hint="$t(`fieldHint.bank`)"
                      type="select"
                      :rules="this.selectedBankCodeValue == 0 ? [] : validator.dropDownListRules()"
                    ></app-form-field>
                    <app-form-field
                      v-model="merchanctBankAccountNumber"
                      :label="$t(`field.depositingBankAccountNumber`)"
                      :readonly="transactionObj.merchantBankCode != 0"
                      :rules="validator.bankAccountNumberRules(1, 20)"
                    ></app-form-field>
                  </div>
                </v-col>
              </div>
              <div class="section-container">
                <v-col cols="12" md="5" class="pa-0">
                  <label>{{ $t(`fieldHint.depositDatetime`) }}</label>
                  <app-date-time-picker label="" type="datetime" v-model="transactionObj.depositDateTime" :rules="validator.dateRules()" :maxDate="depositDatetimeMaxDate"></app-date-time-picker>
                  <label>{{ $t(`field.uploadReceipt`) }}</label>
                  <v-file-input
                    ref="fileimage"
                    accept="image/*"
                    :hint="stringFormat('{0} [{1}]', $t(`field.attachment`), $t(`label.optional`))"
                    class="input-field"
                    prepend-icon
                    outlined
                    append-icon="folder"
                    :rules="transactionObj.rawAttachment != null && transactionObj.rawAttachment != undefined ? validator.depositAttachmentRules() : []"
                    @change="this.uploadDepositAttachement"
                    v-model="transactionObj.rawAttachment"
                    clearable
                  ></v-file-input>
                </v-col>
              </div>
              <div class="section-container">
                <div class="funds-title-wrapper">
                  <label class="text-capitalize subtitle-1 funds-title">{{ $t(`label.yourBankAccountDetails`) }}</label>
                </div>
                <div class="funds-divider"></div>
                <v-col cols="12" md="5" class="pa-0">
                  <app-form-field
                    v-model.trim="transactionObj.memberBankAccountId"
                    :label="$t(`field.memberBank`)"
                    type="select"
                    :rules="validator.dropDownListRules()"
                    :items="memberBankAccounts"
                    :change="this.updateSelectedMemberBankAccount"
                    :displayText="this.memberBankAccountDisplayText"
                  ></app-form-field>
                  <div v-if="transactionObj.memberBankAccountId">
                    <app-form-field v-model.trim="transactionObj.memberBankName" :readonly="true"></app-form-field>
                    <app-form-field v-model.trim="transactionObj.memberBankAccountNumber" :readonly="true"></app-form-field>
                    <app-form-field v-model.trim="transactionObj.memberBankAccountName" :readonly="true"></app-form-field>
                  </div>
                </v-col>
                <div class="pb-2 text-center" v-if="false">
                  <v-btn text :ripple="false" class="text-uppercase font-weight-bold text-center navigator_active&#45;&#45;text text-decoration-underline" @click="openNewBankAccountDialog">
                    {{ $t(transactionObj.memberBankAccountId != '' ? `label.changeBankAccount` : `label.addBankAccount`) }}
                  </v-btn>
                </div>
              </div>
              <v-row no-gutters>
                <v-col cols="6">
                  <app-button class="funds-button" :title="$t(`label.submit`)" :action="this.submitTransaction" :disabled="!isDepositActive"></app-button>
                </v-col>
                <v-col cols="6">
                  <app-button customClass="back-button" :title="$t(`label.back`)" :action="this.backToPreviousStepDetailTab"></app-button>
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>-->
      <!-- End Local Bank -->

      <!-- New Local Bank -->
      <v-stepper v-model="localBankDepositStep" v-if="transactionObj.methodCode == 'NLB'">
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-form ref="localBankStepOneTransactionForm">
              <v-col class="section-container no-gutters">
                <div class="funds-title-wrapper">
                  <label class="text-capitalize subtitle-1 funds-title">{{ $t(`field.paymentType`) }}</label>
                </div>
                <div class="funds-divider"></div>
                <v-row no-gutters class="button-container">
                  <v-col cols="auto" v-for="(method, index) in availableMethods" :key="index">
                    <v-btn
                      :value="method.code"
                      :input-value="transactionObj.methodCode == method.code"
                      :class="'gateway-button'"
                      :active-class="'active-gateway-button'"
                      @click="changeDepositMethod(method)"
                      outlined
                      height="auto"
                    >
                      <img v-if="method.code == 'NLB'" :src="`/static/image/icon/local-bank.png`" />
                      <!--                      <img v-else :src="`/static/svg/deposit-ewallet-${method.code.toLowerCase()}.svg`"/>-->
                      <label>{{ method.name.toUpperCase() }}</label>
                      <div class="tag-recommond"></div>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <div class="section-container" v-if="selectedMethod && selectedMethod.gatewayProviderCode.length > 0">
                <div class="funds-title-wrapper">
                  <label class="funds-title">{{ $t(`field.depositChannel`) }}</label>
                </div>
                <div class="funds-divider"></div>
                <v-row no-gutters :class="$vuetify.breakpoint.mdAndDown ? 'button-container-mobile pb-2' : 'button-container'">
                  <v-btn
                    v-for="(g, index) in selectedMethod.gatewayProviderCode"
                    :value="g.gatewayCode"
                    :input-value="transactionObj.localAccountNumber == g.bankAccount.account_number"
                    :key="index"
                    height="auto"
                    @click="changeLocalBankMethod(g)"
                    :class="'gateway-button'"
                    :active-class="'active-gateway-button'"
                    outlined
                  >
                    <div v-if="g.gatewayCode == 'FAST'" class="tag-recommond"></div>
                    <label v-if="g.gatewayCode != 'FAST'" class="gateway_name">{{ g.gatewayName }}</label>
                    <img v-else class="channelBtnImg" src="/static/image/icon/fastpay.png" />

                    <!--                    <div v-if="g.gatewayCode == 'FAST'" class="tag-recommond"></div>-->
                    <!--                    <img v-if="g.gatewayCode =='FAST'" class="channelBtnImg" src="/static/image/icon/fastpay.png" />-->
                    <!--                    <label v-else-if="g.gatewayCode='BAJIPAY'" class="gateway_name">-->
                    <!--                      <img src="/static/image/payment/bajipay.jpeg" /> {{ g.gatewayName.toUpperCase() }}</label>-->
                    <!--                    <label v-else class="gateway_name">{{ g.gatewayName.toUpperCase() }}</label>-->
                  </v-btn>
                </v-row>
              </div>
              <!-- Bank Card -->
              <div class="section-container">
                <v-col :class="$vuetify.breakpoint.mdAndDown ? 'col-12' : 'col-9'">
                  <label class="input-field-label ma-0 text-capitalize d-block" style="font-size: small" :class="$vuetify.breakpoint.mdAndDown ? 'pb-1' : 'pb-2'">
                    {{ $t(`field.depositInfo`) }}
                  </label>
                  <v-row class="pay-info-group mt-2" style="padding-left: 12px !important;">
                    <v-col :class="$vuetify.breakpoint.mdAndDown ? 'bank-card-mobile card-blue col-10' : 'bank-card card-blue col-8'">
                      <v-col :class="$vuetify.breakpoint.mdAndDown ? 'bank-name pb-0' : 'bank-name'">
                        <div :class="$vuetify.breakpoint.mdAndDown ? 'bank-info-copy pl-0 display-box col-6' : 'bank-info-copy pl-0 display-box col-6'">
                          <p class="localBankDetail_branch">{{ transactionObj.merchantLocalBankCode }}</p>
                          <v-icon class="point icon-color" @click="copyTextGatewayCode()">content_copy</v-icon>
                        </div>
                      </v-col>
                      <v-col :class="$vuetify.breakpoint.mdAndDown ? 'bank-account-mobile bank-info-copy display-box col-10' : 'bank-account bank-info-copy display-box col-9'">
                        <p class="localBankDetail_accountNo">{{ transactionObj.localAccountNumber }}</p>
                        <v-icon class="point icon-color" @click="copyTextAccountNumber()">content_copy</v-icon>
                      </v-col>
                      <v-col :class="$vuetify.breakpoint.mdAndDown ? 'bank-member bank-info-copy display-box col-10' : 'bank-member bank-info-copy display-box col-9'">
                        <p class="localBankDetail_accountName">{{ transactionObj.localAccountName }}</p>
                        <v-icon class="point icon-color" @click="copyTextAccountName()">content_copy</v-icon>
                      </v-col>
                    </v-col>
                  </v-row>
                </v-col>
              </div>
              <!-- End Bank Card -->
              <div class="section-container">
                <div class="funds-title-wrapper">
                  <label class="funds-title">{{ $t(`label.amount`) }}</label>
                </div>
                <div class="funds-divider"></div>
                <v-row no-gutters class="button-container">
                  <v-btn
                    v-for="i in depositAmounts"
                    :key="i"
                    height="auto"
                    width="76"
                    @click="applyDepositAmount(i)"
                    :input-value="transactionObj.amount == i"
                    class="gateway-button"
                    :active-class="'active-gateway-button'"
                    outlined
                  >
                    <label class="amount_cls">{{ i }}</label>
                  </v-btn>
                </v-row>
                <v-text-field
                  :placeholder="
                    selectedMethod != ''
                      ? stringFormat($t(`fieldHint.minMax`), currencyFormatter(selectedMethod.minAmount, transactionObj.currency), currencyFormatter(selectedMethod.maxAmount, transactionObj.currency))
                      : ''
                  "
                  :enabledLabel="false"
                  class="funds-input"
                  clearable
                  type="number"
                  v-model="transactionObj.amount"
                  :change="this.updateMoneyAmount"
                  :keyup="this.checkDepostAmount"
                  :rules="validator.depositAmountRules(2, selectedMethod != '' ? selectedMethod.minAmount : 100, selectedMethod != '' ? selectedMethod.maxAmount : 10000, transactionObj.currency)"
                ></v-text-field>
              </div>
              <v-form ref="bonusForm">
                <v-row no-gutters class="section-container">
                  <div class="funds-title-wrapper">
                    <label class="funds-title">{{ $t(`field.depositBonus`) }}</label>
                  </div>
                  <div class="funds-divider"></div>
                  <v-col cols="12" md="5" v-if="bonusList.length > 0">
                    <app-form-field
                      :enabledLabel="false"
                      :label="$t(`field.depositBonus`)"
                      v-model.trim="bonusObj.id"
                      type="select"
                      :items="bonusList"
                      :displayText="this.getBonusDisplayText"
                      :change="this.checkDepositAmount"
                      :rules="bonusRequiredDepositAmount != '' ? validator.bonusRequiredDepositAmountRules(bonusRequiredDepositAmount, transactionObj.amount, transactionObj.currency) : []"
                      :clearable="true"
                    ></app-form-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-form>
            <v-row no-gutters class="pa-3">
              <app-button customClass="next-button" :title="$t(`button.next`)" :action="this.proceedToMemberDepositDetailTab"></app-button>
            </v-row>
          </v-stepper-content>
          <v-stepper-content step="2" class="px-0">
            <v-form ref="transactionForm">
              <!-- <div class="section-container">
                            <v-col no-gutters cols="12" class="pa-0">
                                <app-form-field
                                    v-model="merchantBankAccounts.find(x => x.value == transactionObj.merchantBankCode).accountName"
                                    :label="$t(`field.depositingBankAccountName`)"
                                    :readonly="true">
                                </app-form-field>
                                <app-form-field
                                    v-model="merchantBankAccounts.find(x => x.value == transactionObj.merchantBankCode).accountNumber"
                                    :enabledLabel="false"
                                    :label="$t(`field.depositingBankAccountNumber`)"
                                    :readonly="transactionObj.merchantBankCode != 0">
                                </app-form-field>
                                <div v-if="transactionObj.merchantBankCode && transactionObj.merchantBankCode == 0">
                                    <app-form-field
                                        v-model.trim="selectedBankCodeValue"
                                        :items="banks.filter(x => x.value != 0)"
                                        :enabledLabel="false"
                                        :label="$t(`field.bank`)"
                                        :hint="$t(`fieldHint.bank`)"
                                        type="select"
                                        :rules="this.selectedBankCodeValue == 0 ? [] : validator.dropDownListRules()">
                                    </app-form-field>
                                    <app-form-field
                                        v-model="merchanctBankAccountNumber"
                                        :label="$t(`field.depositingBankAccountNumber`)"
                                        :readonly="transactionObj.merchantBankCode != 0"
                                        :rules="validator.bankAccountNumberRules(1, 20)">
                                    </app-form-field>
                                </div>
                            </v-col>
                        </div> -->
              <div class="section-container">
                <v-col cols="12" md="5" class="pa-0">
                  <label>{{ $t(`fieldHint.depositDatetime`) }}</label>
                  <app-date-time-picker label="" type="datetime" v-model="transactionObj.depositDateTime" :rules="validator.dateRules()" :maxDate="depositDatetimeMaxDate"></app-date-time-picker>
                  <label>{{ $t(`field.uploadReceipt`) }}</label>
                  <v-file-input
                    ref="fileimage"
                    accept="image/*"
                    :hint="stringFormat('{0}', $t(`field.attachment`))"
                    class="input-field"
                    prepend-icon
                    outlined
                    append-icon="folder"
                    :rules="transactionObj.rawAttachment != null && transactionObj.rawAttachment != undefined ? validator.depositAttachmentRules() : validator.requiredRules()"
                    @change="this.uploadDepositAttachement"
                    v-model="transactionObj.rawAttachment"
                    clearable
                  ></v-file-input>
                </v-col>
              </div>
              <div class="section-container">
                <div class="funds-title-wrapper">
                  <label class="text-capitalize subtitle-1 funds-title">{{ $t(`label.yourBankAccountDetails`) }}</label>
                </div>
                <div class="funds-divider"></div>
                <v-col cols="12" md="5" class="pa-0">
                  <app-form-field
                    v-model.trim="transactionObj.memberBankAccountId"
                    :label="$t(`field.memberBank`)"
                    type="select"
                    :rules="validator.dropDownListRules()"
                    :items="memberBankAccounts"
                    :change="this.updateSelectedMemberBankAccount"
                    :displayText="this.memberBankAccountDisplayText"
                  ></app-form-field>
                  <div v-if="transactionObj.memberBankAccountId">
                    <app-form-field v-model.trim="transactionObj.memberBankName" :readonly="true"></app-form-field>
                    <app-form-field v-model.trim="transactionObj.memberBankAccountNumber" :readonly="true"></app-form-field>
                    <app-form-field v-model.trim="transactionObj.memberBankAccountName" :readonly="true"></app-form-field>
                  </div>
                </v-col>
                <div class="pb-2 text-center" v-if="false">
                  <v-btn text :ripple="false" class="text-uppercase font-weight-bold text-center navigator_active--text text-decoration-underline" @click="openNewBankAccountDialog">
                    {{ $t(transactionObj.memberBankAccountId != '' ? `label.changeBankAccount` : `label.addBankAccount`) }}
                  </v-btn>
                </div>
              </div>
              <v-row no-gutters>
                <v-col cols="6">
                  <app-button class="funds-button" :title="$t(`label.submit`)" :action="this.submitTransaction" :disabled="!isDepositActive"></app-button>
                </v-col>
                <v-col cols="6">
                  <app-button customClass="back-button" :title="$t(`label.back`)" :action="this.backToPreviousStepDetailTab"></app-button>
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
      <!-- End New Local Bank -->

      <!-- Online -->
      <v-form ref="transactionForm" v-if="transactionObj.methodCode != 'NLB'">
        <v-col class="section-container no-gutters">
          <div class="funds-title-wrapper">
            <label class="text-capitalize subtitle-1 funds-title">{{ $t(`field.paymentType`) }}</label>
          </div>
          <div class="funds-divider"></div>
          <v-row no-gutters class="button-container">
            <v-col cols="auto" v-for="(method, index) in availableMethods" :key="index">
              <v-btn
                :value="method.code"
                :input-value="transactionObj.methodCode == method.code"
                :class="'gateway-button'"
                :active-class="'active-gateway-button'"
                @click="changeDepositMethod(method)"
                outlined
                height="auto"
              >
                <img v-if="method.code == 'NLB'" :src="`/static/image/icon/local-bank.png`" />
                <!--                <img v-else :src="`/static/svg/deposit-ewallet-${method.code.toLowerCase()}.svg`"/>-->
                <label class="method_payment">{{ method.name.toUpperCase() }}</label>
                <div class="tag-recommond"></div>
                <v-chip class="percent-label ma-0 pa-1" label color="#f56908" x-small v-if="depositPercentage && !['NLB'].includes(method.code)">
                  <!--                v-if="depositPercentage != false && method.code != 'NLB' && method.code != 'BKASH'">-->
                  <p class="ma-0 pa-0" style="color: #FFFFFF">+ {{ depositPercentage }} %</p>
                </v-chip>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <div class="section-container" v-if="selectedMethod && selectedMethod.gatewayProviderCode.length > 0">
          <div class="funds-title-wrapper">
            <label class="funds-title">{{ $t(`field.depositChannel`) }}</label>
          </div>
          <div class="funds-divider"></div>
          <v-row no-gutters class="button-container">
            <v-btn
              v-for="(g, index) in selectedMethod.gatewayProviderCode"
              :value="g.gatewayCode"
              :input-value="transactionObj.gatewayProviderCode == g.gatewayCode"
              :key="index"
              height="auto"
              @click="transactionObj.gatewayProviderCode = g.gatewayCode"
              :class="'gateway-button'"
              :active-class="'active-gateway-button'"
              outlined
            >
              <div v-if="g.gatewayCode === 'FAST' || 'BAJIPAY' || 'SPEEDPAY'" class="tag-recommond"></div>
              <img v-if="g.gatewayCode === 'FAST'" class="channelBtnImg" src="/static/image/icon/fastpay.png" />
              <label v-else-if="g.gatewayCode === 'BAJIPAY'" class="gateway_name d-flex align-center">
                <img src="/static/image/payment/bajipay.jpeg" />
                {{ g.gatewayName.toUpperCase() }}
              </label>
              <label v-else class="gateway_name">{{ g.gatewayName.toUpperCase() }}</label>
              <!--              <div v-if="depositPercentage !== false && g.gatewayCode === 'FAST' && selectedMethod.code === 'NAGAD'"></div>-->
              <div v-if="depositPercentage !== false && g.gatewayCode === 'SPEEDPAY' && g.gatewayCode === 'FAST' && selectedMethod.code === 'NAGAD'"></div>
              <div v-else-if="depositPercentage !== false && !(g.gatewayCode === 'BAJIPAY' && selectedMethod.code === 'BKASH')"></div>
              <v-chip class="percent-label gateway-label ma-0 pa-1" label color="#f56908" x-small v-else-if="depositPercentage !== false && selectedMethod.code !== 'NLB'">
                <p class="ma-0 pa-0" style="color: #FFFFFF">+ {{ depositPercentage }} %</p>
              </v-chip>
            </v-btn>
          </v-row>
        </div>
        <div class="section-container">
          <div class="funds-title-wrapper">
            <label class="funds-title">{{ $t(`label.amount`) }}</label>
          </div>
          <div class="funds-divider"></div>
          <v-row no-gutters class="button-container">
            <div v-for="i in depositAmounts" :key="i">
              <v-btn
                value="amount"
                height="auto"
                width="76"
                @click="applyDepositAmount(i)"
                class="gateway-button"
                :input-value="transactionObj.amount == i"
                :active-class="'active-gateway-button'"
                outlined
              >
                <label class="amount_cls">{{ i }}</label>
              </v-btn>
            </div>
          </v-row>
          <!-- <app-form-field
                    :tooltip="selectedMethod != '' ? stringFormat($t(`fieldHint.depositAmount`),currencyFormatter(selectedMethod.minAmount, transactionObj.currency),currencyFormatter(selectedMethod.maxAmount, transactionObj.currency)): ''"
                    :placeholder="selectedMethod != '' ? stringFormat($t(`fieldHint.minMax`),currencyFormatter(selectedMethod.minAmount, transactionObj.currency), currencyFormatter(selectedMethod.maxAmount, transactionObj.currency)) : ''"
                    :enabledLabel=false
                    :label="$t(`field.depositAmount`)" :rules="validator.depositAmountRules(2, selectedMethod != '' ? selectedMethod.minAmount : 100, selectedMethod != '' ? selectedMethod.maxAmount : 10000, transactionObj.currency)"
                    v-model="transactionObj.amount"
                    :change="this.updateMoneyAmount"
                    :keyup="this.checkDepostAmount">
                </app-form-field> -->
          <v-text-field
            :prefix="currencySymbolMapp(currentCurrency)"
            :placeholder="
              selectedMethod != ''
                ? stringFormat($t(`fieldHint.minMax`), currencyFormatter(selectedMethod.minAmount, transactionObj.currency), currencyFormatter(selectedMethod.maxAmount, transactionObj.currency))
                : ''
            "
            :enabledLabel="false"
            class="funds-input"
            clearable
            type="number"
            v-model="transactionObj.amount"
            :change="this.updateMoneyAmount"
            :keyup="this.checkDepostAmount"
            :rules="validator.depositAmountRules(2, selectedMethod != '' ? selectedMethod.minAmount : 100, selectedMethod != '' ? selectedMethod.maxAmount : 10000, transactionObj.currency)"
          ></v-text-field>
        </div>

        <v-row no-gutters class="section-container" v-if="isRequiredVerification">
          <div class="funds-title-wrapper">
            <label class="funds-title">{{ $t(`field.depositFrom`) }}</label>
          </div>
          <div class="funds-divider"></div>
          <div class="d-flex align-center">
            <div>
              <v-select
                v-model="phoneNumberList.selectedItem"
                :items="phoneNumberList.items"
                :label="$t(`label.selectANumber`)"
                :rules="isRequiredVerification ? validator.dropDownListRules() : []"
                :isOptional="!isRequiredVerification"
                item-value="value"
                item-text="displayText"
                :no-data-text="$t(`label.pleaseVerifyPhone`)"
                return-object
              >
                <!--                <template #append-outer>-->
                <!--                  <v-btn small color="primary" @click="verify(phoneNumberList.selectedItem)">Verify</v-btn>-->
                <!--                </template>-->
              </v-select>
            </div>
          </div>
        </v-row>

        <!-- <v-row no-gutters align="end" class="px-8" v-if="memberBankInfoRequired">
                <v-col md=5>
                  <app-form-field v-model.trim="transactionObj.memberBankAccountId" :label="$t(`field.memberBank`)" :hint="$t(`fieldHint.memberBank`)" type="select" :rules="validator.dropDownListRules()" :items="memberBankAccounts" :change="this.updateSelectedMemberBankAccount"
                    :displayText="this.memberBankAccountDisplayText"></app-form-field>
                </v-col>
              </v-row> -->
        <v-form ref="bonusForm">
          <v-row no-gutters class="section-container">
            <div class="funds-title-wrapper">
              <label class="funds-title">{{ $t(`field.depositBonus`) }}</label>
            </div>
            <div class="funds-divider"></div>
            <app-form-field
              v-model.trim="bonusObj.id"
              :enabledLabel="false"
              :label="$t(`field.depositBonus`)"
              type="select"
              :items="bonusList"
              :displayText="this.getBonusDisplayText"
              :change="this.checkDepositAmount"
              :rules="bonusRequiredDepositAmount != '' ? validator.bonusRequiredDepositAmountRules(bonusRequiredDepositAmount, transactionObj.amount, transactionObj.currency) : []"
              :clearable="false"
            ></app-form-field>
            <!-- <v-text-field
                        :placeholder="selectedMethod != '' ? stringFormat($t(`fieldHint.minMax`),currencyFormatter(selectedMethod.minAmount, transactionObj.currency), currencyFormatter(selectedMethod.maxAmount, transactionObj.currency)) : ''"
                        :enabledLabel="false"
                        class="deposit-input"
                        :clearable=
                        v-model.trim="bonusObj.id">
                    </v-text-field> -->
          </v-row>
        </v-form>
        <v-row no-gutters v-if="bonusList.length > 0" class="pa-3">
          <app-button customClass="deposit-button buttonPrimary" :title="$t(`label.deposit`)" :action="this.showConfirmationDialog" :disabled="!isDepositActive"></app-button>
        </v-row>
      </v-form>
      <!-- End Online -->
      <!-- <div v-else>
              <div class="bank-page-form">
                <div v-if="transactionResponseResult.success">
                  <v-card-title v-if="transactionObj.methodCode != 'upqr'">
                    <label class="ma-auto font-weight-bold subtitle-1 text-capitalize text-center">{{
                        $t(`message.transactionSubmitted`)
                      }}</label>
                    <label class="ma-auto subtitle-1 d-block full-width text-center">{{
                        $t(`message.transactionProcessing`)
                      }}</label>
                    <label class="ma-auto subtitle-1 d-block full-width text-center">{{
                        $t(`message.receiveNotification`)
                      }}</label>
                  </v-card-title>
                  <v-card-title v-else>
                    <label class="ma-auto font-weight-bold subtitle-1 text-capitalize text-center">{{
                        $t(`message.transactionSubmitted`)
                      }}</label>
                    <label class="ma-auto subtitle-1 d-block full-width text-center">{{
                        $t(`message.scanTransactionQRCode`)
                      }}</label>
                    <label class="ma-auto subtitle-1 d-block full-width text-center">{{
                        $t(`message.warningScanTransactionQRCode`)
                      }}</label>
                  </v-card-title>
                  <vue-qr-code v-if="qrCodeUrl != ''" class="qrCode" :text="qrCodeUrl"></vue-qr-code>
                </div>
                <div v-else>
                  <v-card-title>
                    <label class="ma-auto font-weight-bold subtitle-1 text-capitalize">{{
                        $t(`message.transactionRejected`)
                      }}</label>
                    <label v-if="transactionResponseResult.code != 0"
                           class="ma-auto subtitle-1 d-block full-width text-center red--text">
                      {{
                        $t(`errorCodeDesc.${transactionResponseResult.code.toString().replace(/\./g, '_')}`)
                      }}
                    </label>
                    <label v-else class="ma-auto subtitle-1 d-block full-width text-center">{{
                        $t(`message.contactCs`)
                      }}</label>
                  </v-card-title>
                </div>
                <div v-if="applyBonusResponseResult.complete">
                  <v-card-title>
                    <label class="ma-auto font-weight-bold subtitle-1 text-capitalize">{{
                        $t(`message.applyBonus`)
                      }}</label>
                    <label v-if="applyBonusResponseResult.complete && applyBonusResponseResult.code != 0"
                           class="ma-auto subtitle-1 d-block full-width text-center red--text">
                      {{
                        $t(`errorCodeDesc.${applyBonusResponseResult.code.toString().replace(/\./g, '_')}`)
                      }}
                    </label>
                    <label v-if="applyBonusResponseResult.complete && applyBonusResponseResult.code == 0"
                           class="ma-auto subtitle-1 d-block full-width text-center">
                      {{ $t(`message.applyBonusSuccess`) }}
                    </label>
                  </v-card-title>
                </div>
                <app-button :title="$t(`label.makeAnotherTransaction`)"
                            :action="this.makeNewTransaction"></app-button>
                <div v-if="transactionResponseResult.success">
                  <app-button :action="this.viewTransactionHistory" :title="$t(`label.viewTransactionHistory`)"
                              :customClass="'secondary_button buttonWhite'"></app-button>
                </div>
              </div>
            </div> -->
    </v-card>
    <app-dialog :dialogShow="newBankAccountDialogShow" :max-width="600" :title="$t(`label.bankAccount`)" :closeAction="this.closeNewBankAccountDialog">
      <app-account
        :showFullBankForm="true"
        :banks="banks"
        :bankAccountDetail="bankAccountDetail"
        :closeNewBankAccountDialog="this.closeNewBankAccountDialog"
        @updateBankAccountObject="updateBankAccountData"
      ></app-account>
    </app-dialog>
    <app-dialog
      :dialogShow="msgDialog.show"
      ref="depositConfirmationDialog"
      :max-width="400"
      :title="$t(`label.depositDetails`)"
      :header_title="$t(`label.depositDetails`)"
      :closeAction="this.onCloseClick"
    >
      <v-row no-gutters justify="center" :class="$vuetify.breakpoint.mdAndDown ? 'px-3' : 'px-5'">
        <v-col cols="6">
          <label class="payment-dialog-title">{{ $t(`field.depositAmount`) }}</label>
        </v-col>
        <v-col cols="6" class="text-right">
          {{ transactionObj.amount | currency(transactionObj.currency) }}
        </v-col>
        <v-col cols="6" class="mt-2">
          <label class="payment-dialog-title">{{ $t(`field.bonusAmount`) }}</label>
        </v-col>
        <v-col cols="6" class="text-right mt-2">
          {{ bonusAmount | currency(transactionObj.currency) }}
        </v-col>
        <v-col cols="6" class="mt-2 mb-6">
          <label class="payment-dialog-title">{{ $t(`field.targetTurnover`) }}</label>
        </v-col>
        <v-col cols="6" class="text-right mt-2 mb-6">
          {{ bonusTurnOver | currency(transactionObj.currency) }}
        </v-col>
        <v-col cols="8">
          <v-btn height="auto" @click="submitTransaction" depressed color="var(--v-primary-base)" class="primary-button text-capitalize pa-2 font-weight-bold white--text">
            {{ $t(`label.confirm`) }}
          </v-btn>
        </v-col>
      </v-row>
    </app-dialog>
    <app-dialog
      :dialogShow="showDepositUnavailableDialog"
      ref="depositUnavailableDialog"
      :max-width="400"
      :title="$t(`label.system`)"
      :header_title="$t(`label.system`)"
      :closeAction="this.routeToHome"
    >
      <v-row no-gutters justify="center" :class="$vuetify.breakpoint.mdAndDown ? 'px-3' : 'px-5'">
        <v-col cols="12" class="text-center">
          <p>{{ $t(`message.unableDeposit`) }}</p>
        </v-col>
        <v-col cols="12" class="text-center">
          <p>
            {{ $t(`message.unableDeposit_1`) }}
            <br />
            {{ $t(`message.unableDeposit_2`) }}
          </p>
        </v-col>
        <v-col cols="8">
          <v-btn height="auto" @click="routeToHome()" depressed color="primary" class="primary-button text-capitalize pa-2 font-weight-bold">{{ $t(`label.confirm`) }}</v-btn>
        </v-col>
      </v-row>
    </app-dialog>
    <v-snackbar class="pa-0" style="z-index:1000 !important;top:7%" light timeout="-1" v-model="alertShow" width="fit-content" top centered>
      <v-alert
        v-model="alertShow"
        dismissible
        :icon="alertSuccess ? 'check_circle' : 'cancel'"
        class="mb-0 pa-1"
        :class="alertSuccess ? 'profile-alert-success' : 'profile-alert-failed'"
        light
        close-icon="close"
      >
        {{ alertMessage }}
      </v-alert>
    </v-snackbar>
  </div>
</template>

<script>
import NavFastPay from '@/assets/svg/fast_pay.svg'
import _ from 'lodash'
import currencyFormat from '@/filters/currency-format'
import { DataType, DevicePlatform, ProcessTransactionResponseType } from '@/constants/enums'
import { ROUTE_NAME } from '@/constants/route.constants'
import VueQrCode from 'vue-qrcode-component'
import format from 'string-format'
import AppAccount from '@/components/bank/account.vue'
import { SHARED, SESSION, API } from '@/constants/constants'
import { formValidator, locale, uiHelper, errorCodeHelper } from '@/util'
import { MEMBER_CHECK_TOKEN_VALIDITY, MEMBER_CHECK_FIRST_DEPOSIT_REQ, MEMBER_RESET_CHECK_FIRST_DEPOSIT_REQ } from '@/store/member.module'
import {
  PAYMENT_MERCHANT_BANK_ACCOUNT,
  PAYMENT_SUBMIT_PAYMENT_TRANSACTION,
  PAYMENT_AVAILABLE_METHOD,
  PAYMENT_MEMBER_BANK_ACCOUNT,
  PAYMENT_RESET_TRANSACTION_RESPONSE_STORE,
  PAYMENT_ONLINE_PAYMENT_SUPPORTED_BANK,
  PAYMENT_PROCESS_TRANSACTION,
  PAYMENT_RESET_PAYMENT_PROCESS_TRANSACTION_RESPONSE_STORE,
  PAYMENT_BANK,
  DEPOSIT_PERCENTAGE_CONFIGURATION
} from '@/store/payment.module'
import { BONUS_LIST, BONUS_APPLY_BONUS, BONUS_RESET_APPLY_BONUS_RESPONSE_STORE } from '@/store/bonus.module'
import currencySymbolMapp from 'currency-symbol-map'

export default {
  name: 'deposit',
  metaInfo: {
    title: `${locale.getMessage('meta.deposit_title')}`,
    titleTemplate: `%s`,
    meta: [
      {
        charset: 'utf-8'
      },
      {
        name: 'description',
        content: `${locale.getMessage('meta.deposit_description')}`
      },
      {
        name: 'keyword',
        content: `${locale.getMessage('meta.deposit_keyword')}`
      }
    ]
  },
  components: {
    AppAccount,
    VueQrCode,
    NavFastPay
  },
  data: () => ({
    isRequiredVerification: false,
    phoneNumberList: [{ selectedItem: null, items: [] }],
    currentCurrency: uiHelper.getCurrency(),
    currencySymbolMapp: currencySymbolMapp,
    depositAmounts: [200, 500, 1000, 2000, 10000, 20000],
    bankAccountDetail: {
      memberBankAccountId: '',
      memberBankAccountName: '',
      memberBankAccountNumber: '',
      memberBankName: '',
      memberBankProvince: '',
      memberBankBranch: '',
      memberBankCity: '',
      saveNewBank: false,
      memberCode: localStorage.getItem(SESSION.USERNAME),
      isUpdateBank: false
    },
    bonusTransactionAmount: 0,
    bonusTransactionId: 0,
    depositDatetimeMaxDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString(),
    currencyFormatter: currencyFormat,
    dataType: DataType,
    selectedMethod: '',
    currentLanguage: uiHelper.getLanguage(),
    depositingBankFieldRequired: false,
    memberBankInfoRequired: false,
    newBankAccountDialogShow: false,
    localBankDepositStep: 1,
    stringFormat: format,
    validator: formValidator,
    bonusObj: {
      id: ''
    },
    selectedBankCodeValue: '',
    merchanctBankAccountNumber: '',
    tempMemberBankAccountId: '',
    transactionObj: {
      /* TRANSACTION INFO */
      transactionType: SHARED.DEPOSIT,
      methodCode: '',
      amount: '500',
      depositDateTime: '',
      attachmentFileType: '',
      attachment: '',
      rawAttachment: undefined,
      ipAddress: '',
      saveNewBank: false,
      gatewayProviderCode: '',
      /* MEMBER INFO */
      memberCode: localStorage.getItem(SESSION.USERNAME),
      memberRealName: '',
      currency: localStorage.getItem(SESSION.CURRENCY),
      memberBankAccountId: '',
      memberBankAccountName: '',
      memberBankAccountNumber: '',
      memberAccountBankCode: '',
      memberBankName: '',
      memberBankCode: '',
      memberBankProvince: '',
      memberBankBranch: '',
      memberBankCity: '',
      memberEmail: '',
      memberContactIdorNumber: '',
      /* MERCHANT or ONLINE GATEWAY INFO */
      localAccountNumber: '',
      localAccountName: '',
      merchantBankCode: '',
      gatewayAccountId: '',
      gatewayAccountBankId: '',
      /* OTHER */
      transactionSuccessUrl: uiHelper.getHostname(),
      oriCurrency: '',
      oriAmount: '',
      language: uiHelper.getLanguage(),
      platform: uiHelper.getPlatform()
    },
    transactionResponseResult: {
      success: false,
      complete: false,
      code: 0
    },
    applyBonusResponseResult: {
      success: false,
      complete: false,
      code: 0
    },
    qrCodeUrl: '',
    bonusRequiredDepositAmount: '',
    submittedTransactionResponse: null,
    msgDialog: {
      show: false,
      title: '',
      msg: ''
    },
    bonusAmount: 0,
    bonusTurnOver: 0,
    showDepositUnavailableDialog: false,
    isDepositActive: true,
    alertShow: false,
    alertSuccess: false,
    alertMessage: ''
  }),
  computed: {
    depositPercentage() {
      return this.$store.state.payment.depositPercentageConfiguration
    },
    applyBonusResponseComplete() {
      return this.$store.state.bonus.applyBonusResponse.complete
    },
    depositAmount() {
      return this.transactionObj.amount
    },
    banks() {
      return this.$store.state.payment.banks
    },
    availableMethods() {
      let k = this.$store.state.payment.availableDepositMethods
      try {
        return k.sort((a, b) => {
          const specialPriority = ['NAGAD', 'BKASH']

          if (specialPriority.includes(a.code) && !specialPriority.includes(b.code)) {
            return -1
          } else if (!specialPriority.includes(a.code) && specialPriority.includes(b.code)) {
            return 1
          } else if (specialPriority.includes(a.code) && specialPriority.includes(b.code)) {
            return specialPriority.indexOf(a.code) - specialPriority.indexOf(b.code)
          } else {
            return 0
          }
        })
      } catch (err) {
        console.error('Error during sorting:', err)
        return k
      }
    },
    memberInfo() {
      return this.$store.state.member.info
    },
    memberPhoneVerified() {
      return this.$store.state.member.info.mobile.is_verified
    },
    merchantBankAccounts() {
      // return this.$store.state.payment.merchantBankAccounts
      return this.selectedMethod.bankAccounts
    },
    memberBankAccounts() {
      return this.$store.state.payment.memberBankAccounts
    },
    bonusList() {
      let bonusList = _.cloneDeep(this.$store.state.bonus.bonusList)
      let nobonus = {
        name: 'No Bonus',
        value: '',
        locale: [],
        memberGroup: []
      }
      bonusList = [nobonus, ...bonusList]
      return bonusList
    },
    trasnactionResponse() {
      return this.$store.state.payment.trasnactionResponse
    },
    transactionResponseComplete() {
      return this.$store.state.payment.trasnactionResponse.complete
    },
    onlinePaymentSupportedBanks() {
      // return this.$store.state.payment.onlinePaymentSupportedBanks
      return this.selectedMethod.banks
    },
    processTransactionResponseComplete() {
      return this.$store.state.payment.processTransactionResponse.complete
    },
    isFirstDepositSmsRequired() {
      return this.$store.state.member.firstDepositVerification.complete
    }
  },
  watch: {
    memberPhoneVerified() {
      return this.$store.state.member.info.mobile.is_verified
    },
    memberInfo() {
      let p = []
      let d = this.$store.state.member.info
      if (d != null) {
        if (d.mobileList != null && d.mobileList.length > 0) {
          d.mobileList.forEach(data => {
            if (data.is_verified) {
              p.push({
                displayText: '+88-0' + data.value,
                value: data.value,
                is_verified: data.is_verified,
                is_primary: data.is_primary
              })
            }
          })

          this.phoneNumberList.items = [...p]
        }
      }
      return d
    },
    memberBankAccounts() {
      //this.preSelectMemberBankAccount()
    },
    applyBonusResponseComplete() {
      let response = this.$store.state.bonus.applyBonusResponse
      if (response.complete) {
        try {
          this.$ga.event(
            'Bonus',
            `Apply Bonus`,
            `Bonus ID - ${this.bonusTransactionId} - ${response.success ? 'Success' : 'Fail'}${!response.success ? ` [Error : ${response.code}]` : ''}`,
            response.success ? parseFloat(this.bonusTransactionAmount) : 0
          )
        } catch (err) {
          console.log(err)
        }
        this.bonusTransactionAmount = 0
        this.bonusTransactionId = 0
        this.applyBonusResponseResult = {
          success: response.success,
          complete: response.complete,
          code: response.code
        }
        this.$store.dispatch(`${BONUS_RESET_APPLY_BONUS_RESPONSE_STORE}`)
      }
    },
    availableMethods() {
      let depositActive = this.$store.state.payment.availableDepositMethods.find(method => {
        return method.isActive
      })
      this.isDepositActive = depositActive != undefined
      if (!this.isDepositActive) {
        this.showDepositUnavailableDialog = true
      }
      if (this.$store.state.payment.availableDepositMethods[0].gatewayProviderCode[0].bankAccount != undefined) {
        let localBank = this.$store.state.payment.availableDepositMethods[0].gatewayProviderCode
        this.transactionObj.localAccountName = localBank[0].bankAccount.account_name
        this.transactionObj.localAccountNumber = localBank[0].bankAccount.account_number
        this.transactionObj.merchantLocalBankCode = localBank[0].bankAccount.bank_account_code
        this.transactionObj.gatewayName = localBank[0].gatewayName
      }

      this.changeDepositMethod(this.$store.state.payment.availableDepositMethods[0])
    },
    trasnactionResponse() {
      if (this.trasnactionResponse.complete) {
        if (!this.trasnactionResponse.success) {
          this.openAlert(this.trasnactionResponse.success, this.trasnactionResponse.error)
        }
      }
    },
    transactionResponseComplete() {
      let transactionResponse = this.$store.state.payment.trasnactionResponse
      if (transactionResponse.complete) {
        try {
          this.$ga.event(
            'Payment',
            `Deposit`,
            `${this.transactionObj.methodCode.toUpperCase()} - ${transactionResponse.success ? 'Success' : 'Fail'}${!transactionResponse.success ? ` [Error : ${transactionResponse.code}]` : ''}`,
            transactionResponse.success ? this.transactionObj.amount : 0
          )
        } catch (err) {
          console.log(err)
        }
        this.transactionComplete(transactionResponse)
      }
    },
    processTransactionResponseComplete() {
      let processTransactionResponse = this.$store.state.payment.processTransactionResponse
      if (processTransactionResponse.complete) {
        try {
          this.$ga.event(
            'Payment',
            `Process Deposit`,
            `${this.transactionObj.methodCode.toUpperCase()} - ${processTransactionResponse.success ? 'Success' : 'Fail'}${
              !processTransactionResponse.success ? ` [Error : ${processTransactionResponse.code}]` : ''
            }`,
            processTransactionResponse.success ? this.transactionObj.amount : 0
          )
        } catch (err) {
          console.log(err)
        }
        this.processTransactionResponseDialog(processTransactionResponse)
        this.$store.dispatch(`${PAYMENT_RESET_PAYMENT_PROCESS_TRANSACTION_RESPONSE_STORE}`)
      }
    },
    isFirstDepositSmsRequired() {
      if (this.$store.state.member.firstDepositVerification.complete) {
        if (this.$store.state.member.firstDepositVerification.success) {
          this.isRequiredVerification = this.$store.state.member.firstDepositVerification.req_verification
        }
        this.$store.dispatch(`${MEMBER_RESET_CHECK_FIRST_DEPOSIT_REQ}`)
      }
    }
  },
  mounted() {},
  created() {
    this.checkOnlineDepositSuccessTransaction()
    this.checkFirstSmsVerificationRequired()
    this.preSelectBonusId()
    this.checkMemberTokenValidity()
    this.getPaymentMethod()
    this.getMemberBankAccount()
    this.getAvailableBonus()
    this.getBank()
    this.getConfigurationStatus()
    // this.getGatewaySupportedBank()
  },
  methods: {
    getConfigurationStatus() {
      this.$store.dispatch(`${DEPOSIT_PERCENTAGE_CONFIGURATION}`)
    },
    checkFirstSmsVerificationRequired() {
      this.$store.dispatch(`${MEMBER_CHECK_FIRST_DEPOSIT_REQ}`)
    },
    copyTextGatewayName() {
      navigator.clipboard.writeText(this.transactionObj.gatewayName)
    },
    copyTextGatewayCode() {
      navigator.clipboard.writeText(this.transactionObj.gatewayCode)
    },
    copyTextAccountNumber() {
      navigator.clipboard.writeText(this.transactionObj.localAccountNumber)
    },
    copyTextAccountName() {
      navigator.clipboard.writeText(this.transactionObj.localAccountName)
    },
    changeLocalBankMethod(method) {
      this.transactionObj.gatewayName = method.gatewayName
      this.transactionObj.gatewayCode = method.gatewayCode
      this.transactionObj.merchantLocalBankCode = method.bankAccount.bank_account_code
      this.transactionObj.localAccountName = method.bankAccount.account_name
      this.transactionObj.localAccountNumber = method.bankAccount.account_number
    },
    getBank() {
      let obj = {
        bankName: '',
        bankCode: '',
        currency: uiHelper.getCurrency(),
        Page: '',
        ItemsPerpage: '',
        orderBy: '',
        order: ''
      }
      this.$store.dispatch(`${PAYMENT_BANK}`, {
        obj
      })
    },
    applyDepositAmount(amount) {
      this.transactionObj.amount = amount
    },
    RedirectToProfilePage() {
      this.$router.push({ name: ROUTE_NAME.PERSONAL })
    },
    getAvailableMethodDisplayName(method) {
      let self = this
      let localization = method.localization.find(m => m.language == self.transactionObj.language)
      if (!localization) {
        return method.name
      }
      return localization.description
    },
    preSelectMemberBankAccount() {
      if (this.memberBankAccounts.length > 0) {
        let memberBankAccounts = this.memberBankAccounts.find(x => x.id != '0')
        if (memberBankAccounts != null && memberBankAccounts != undefined) {
          this.transactionObj.memberBankAccountId = memberBankAccounts.id
          this.transactionObj.memberBankName = memberBankAccounts.bankName
          this.transactionObj.memberBankAccountNumber = memberBankAccounts.accountNumber
          this.transactionObj.memberBankAccountName = memberBankAccounts.accountName
        } else {
          this.promptAddBankAccount()
        }
      } else {
        this.promptAddBankAccount()
      }
    },
    initializePageDialogProperty() {
      let initPageDialog = {
        title: locale.getMessage(`label.system`),
        dialogXButton: null,
        messageTitle: '',
        message: [],
        button: []
      }
      return initPageDialog
    },
    promptAddBankAccount() {
      let dialog = this.initializePageDialogProperty()
      dialog.title = locale.getMessage(`label.info`)
      dialog.message.push(locale.getMessage(`message.please_add_bank_account`))
      dialog.button.push({
        title: locale.getMessage(`label.ok`),
        action: () => this.redirectToBankAccountPage()
      })
      this.$eventHub.$emit('open-page-dialog', dialog)
    },
    maximumImageSizeDialog() {
      let dialog = this.initializePageDialogProperty()
      dialog.title = locale.getMessage(`label.info`)
      dialog.message.push(locale.getMessage(`message.image_too_large`))
      dialog.button.push({
        title: locale.getMessage(`label.ok`),
        action: () => this.closePageDialog()
      })
      this.$eventHub.$emit('open-page-dialog', dialog)
    },
    redirectToBankAccountPage() {
      this.closePageDialog()
      this.$router.push({
        name: ROUTE_NAME.BANK_ACCOUNT
      })
    },
    closePageDialog() {
      this.$eventHub.$emit('close-page-dialog', true)
    },
    checkDepositAmount(bonusId) {
      this.bonusRequiredDepositAmount = ''
      let memberGroup = this.memberInfo.group
      let selectedApplyBonus = this.bonusList.find(x => x.id == bonusId)
      if (selectedApplyBonus) {
        let selectedApplyBonusMemberGroup = selectedApplyBonus.memberGroup.find(x => x.member_group.toLowerCase() == memberGroup.toLowerCase())
        if (selectedApplyBonusMemberGroup != null) {
          this.bonusRequiredDepositAmount = selectedApplyBonusMemberGroup.deposit_amount
        } else {
          this.bonusRequiredDepositAmount = selectedApplyBonus.depositAmount
        }
      }
    },
    getMerchantBankAccountsText(data) {
      return data.accountName
    },
    updateMoneyAmount(data) {
      this.transactionObj.amount = data.replace(/^0+/, '')
    },
    validateBonusForm() {
      if (this.bonusList.length > 0) {
        return this.$refs.bonusForm.validate()
      }
      return true
    },
    checkDepostAmount() {
      if (this.bonusList.length > 0) {
        this.$refs.bonusForm.validate()
      }
    },
    checkOnlineDepositSuccessTransaction() {
      /* CHECK ROUTE FROM DEPOSIT SUCCESS */
      /* let transactionId = localStorage.getItem(SESSION.DEPOSIT_TRANSACTION_ID)
            let transactionAmount = localStorage.getItem(SESSION.DEPOSIT_AMOUNT)
            let transactionBonusId = localStorage.getItem(SESSION.DEPOSIT_BONUS_ID)
            let action = this.$route.params.action
            if (action != null && action != undefined && action != '' && action == 'applyBonus') {
              localStorage.removeItem(SESSION.DEPOSIT_TRANSACTION_ID)
              localStorage.removeItem(SESSION.DEPOSIT_AMOUNT)
              localStorage.removeItem(SESSION.DEPOSIT_BONUS_ID)
              this.applyBonus(transactionBonusId, transactionId, transactionAmount)
            } */
    },
    applyBonus(bonusId, transactionId, transactionAmount) {
      this.bonusTransactionAmount = transactionAmount
      this.bonusTransactionId = bonusId
      if (bonusId) {
        let applyBonusObj = {
          bonusId: bonusId,
          depositTransactionId: transactionId,
          depositAmount: transactionAmount
        }
        this.$store.dispatch(`${BONUS_APPLY_BONUS}`, {
          applyBonusObj
        })
      }
    },
    processTransactionResponseDialog(response) {
      if (response.code != 0 && !response.success) {
        // let dialog = this.$parent.$parent.initializePageDialogProperty()
        // dialog.title = locale.getMessage(`label.${response.action}`)
        // dialog.message.push(errorCodeHelper.getErrorCodeDesc(response.code))
        // dialog.button.push({
        //   title: locale.getMessage(`label.close`),
        //   action: this.$parent.$parent.closePageDialog
        // })
        // this.$parent.$parent.openPageDialog(dialog)
        this.msgDialog.show = false
        let msg = response.error != null ? response.error : locale.getMessage(`message.paymentError`)
        this.openAlert(false, msg)
      } else {
        if (this.transactionObj.methodCode !== 'NLB') {
          if (this.transactionObj.gatewayProviderCode == 'DP') {
            let routeData = this.$router.resolve({
              name: ROUTE_NAME.PAY_SECURE,
              query: {
                payment_url: response.data.payment_url,
                MerchantCode: response.data.post_body.MerchantCode,
                CustomerCode: response.data.post_body.CustomerCode,
                PhoneNumber: response.data.post_body.PhoneNumber,
                Currency: response.data.post_body.Currency,
                BankCode: response.data.post_body.BankCode,
                Amount: response.data.post_body.Amount,
                LanguageCode: response.data.post_body.LanguageCode,
                SourceAccNo: response.data.post_body.SourceAccNo,
                TransactionId: response.data.trans_id,
                Reference: response.data.post_body.Reference,
                Timestamp: response.data.post_body.Timestamp,
                ClientIP: response.data.post_body.ClientIP,
                Signature: response.data.post_body.Signature,
                ResponseURL: response.data.post_body.ResponseURL,
                BackendURL: response.data.post_body.BackendURL
              }
            })
            //uiHelper.openPopup(routeData.href, 'Pay Secure', 660, 550)
            this.msgDialog.show = false
            window.scrollTo(0, 0)
            this.openAlert(true, locale.getMessage(`message.depositSubmitSuccess`))
            this.$refs.transactionForm.reset()
            this.$refs.bonusForm.reset()

            this.$ga.event('DPay', 'Submit', 'Pending', 1)
            let gaCode = SHARED.GA_TRACKING_CODE
            let qString = routeData.href.split('?')[1] + '&ga=' + gaCode

            let jumpingUrl = '/static/html/payment/jumpingPage.html?' + qString
            window.open(jumpingUrl, 'Process Transaction', 'width=660,height=550', '_blank')
          } else if (this.transactionObj.gatewayProviderCode == 'SPEEDPAY' || this.transactionObj.gatewayProviderCode == 'FAST' || this.transactionObj.gatewayProviderCode == 'BAJIPAY') {
            this.msgDialog.show = false
            window.scrollTo(0, 0)
            this.openAlert(true, locale.getMessage(`message.depositSubmitSuccess`))
            this.$refs.transactionForm.reset()
            this.$refs.bonusForm.reset()

            this.$ga.event(this.transactionObj.gatewayProviderCode, 'Submit', 'Pending', 1)
            let gaCode = SHARED.GA_TRACKING_CODE
            window.open(response.data.payment_url, 'Process Transaction', 'width=660,height=550', '_blank')
          }
        } else {
          let processTransactionResponseType = this.submittedTransactionResponse.processingType
          let processTransactionResponseData = response.data
          if (processTransactionResponseType == ProcessTransactionResponseType.HTML_AUTO_REDIRECT || processTransactionResponseType == ProcessTransactionResponseType.HTML_REDIRECT) {
            let routeData = this.$router.resolve({
              name: ROUTE_NAME.PROCESS_TRANSACTION,
              query: {
                html: processTransactionResponseData
              }
            })
            window.open(routeData.href, 'Process Transaction', 'width=660,height=550', '_blank')
          } else if (processTransactionResponseType == ProcessTransactionResponseType.URL_LINK || processTransactionResponseType == ProcessTransactionResponseType.IMAGE_URL) {
            if (uiHelper.getPlatform() == DevicePlatform.MOBILE) {
              window.location = processTransactionResponseData
            } else {
              uiHelper.openPopup(processTransactionResponseData, 'Process Transaction', 1200, 900)
            }
          } else if (processTransactionResponseType == ProcessTransactionResponseType.IMAGE_DATA) {
            this.qrCodeUrl = processTransactionResponseData
          } else {
            this.msgDialog.show = false
            this.openAlert(true, locale.getMessage(`message.depositSubmitSuccess`))
            this.$refs.localBankStepOneTransactionForm.reset()
            this.localBankDepositStep = 1
            window.scrollTo(0, 0)
            this.$refs.transactionForm.reset()
            this.$refs.bonusForm.reset()
          }
        }
      }
      this.transactionResponseResult = {
        success: response.success,
        complete: response.complete,
        code: response.code
      }
    },
    transactionComplete(response) {
      this.$store.dispatch(`${PAYMENT_RESET_TRANSACTION_RESPONSE_STORE}`)
      if (response.success) {
        this.submittedTransactionResponse = response.data
        if (this.submittedTransactionResponse.platformType == ProcessTransactionResponseType.HTML_AUTO_REDIRECT) {
          let epsProcessTransactionUrl = API.PAYMENT_URL + '/Transactions/' + response.id + '/ProcessTransaction'
          if (uiHelper.getPlatform() == DevicePlatform.MOBILE) {
            window.location = epsProcessTransactionUrl
          } else {
            window.open(epsProcessTransactionUrl, 'Process Transaction', 'width=660,height=550', '_blank')
          }
        } else {
          let processTransactionObj = {
            transactionId: response.id
          }
          this.$store.dispatch(PAYMENT_PROCESS_TRANSACTION, {
            processTransactionObj
          })
        }
        /* SAVE TRANSACTION INFO TO APPLY BONUS FOR ONLINE DEPOSIT */
        this.applyBonus(this.bonusObj.id, response.id, this.transactionObj.amount)
      } else {
        this.msgDialog.show = false
        if (response.code == '25.70.0') {
          this.openAlert(false, errorCodeHelper.getErrorCodeDesc(response.code))
        } else if (response.code == '25.61.0') {
          this.openAlert(false, errorCodeHelper.getErrorCodeDesc(response.code))
        } else if (response.code == '25.62.0') {
          this.openAlert(false, errorCodeHelper.getErrorCodeDesc(response.code))
        } else {
          this.openAlert(false, locale.getMessage(`message.paymentError`))
        }
      }
      this.transactionResponseResult = {
        success: response.success,
        complete: response.complete,
        code: response.code
      }
    },
    processPaymentTransaction() {
      let processTransactionObj = {
        transactionId: this.processTrxnId
      }
      this.$store.dispatch(PAYMENT_PROCESS_TRANSACTION, {
        processTransactionObj
      })
      this.onCloseClick()
    },
    changeDepositMethod(method) {
      this.selectedMethod = method
      this.depositingBankFieldRequired = method && ['NLB', 'PN'].indexOf(method.code.toLowerCase()) > -1
      this.memberBankInfoRequired = method.code.toLowerCase() == 'olb'
      this.transactionObj.methodCode = method.code

      if (this.selectedMethod.code.toLowerCase() === 'rocket') {
        const preferred_sort = [{ method_code: 'FAST' }, { method_code: 'BAJIPAY' }].map(x => x.method_code)
        this.gatewayChannel = method.gatewayProviderCode.sort(function(a, b) {
          return preferred_sort.indexOf(a.gatewayCode) - preferred_sort.indexOf(b.gatewayCode)
        })
      }
      this.gatewayChannel = method.gatewayProviderCode
      this.transactionObj.gatewayProviderCode = this.gatewayChannel[0].gatewayCode
      //fixed from google sheet item 33, do not clear amt when change method
      //this.transactionObj.amount = undefined
      if (method.code == 'NLB') {
        this.localBankDepositStep = 1
        this.preSelectMemberBankAccount()
        this.transactionObj.gatewayAccountBankId = ''
      } else {
        this.transactionObj.memberBankAccountId = ''
        this.transactionObj.memberBankName = ''
        this.transactionObj.memberBankAccountNumber = this.memberInfo.mobile.value
        this.transactionObj.memberBankAccountName = ''
        this.transactionObj.gatewayAccountBankId = method.code
        // this.transactionObj.gatewayAccountBankId = this.onlinePaymentSupportedBanks[0].code
        this.gatewayAccountBankName = method.name
      }
    },
    preSelectBonusId() {
      let bonusId = this.$route.params.bonusId
      if (bonusId) {
        this.bonusObj.id = bonusId
      }
    },
    updateBankAccountData(bankAccountObj) {
      if (bankAccountObj != null) {
        this.tempMemberBankAccountId = '0'
        this.transactionObj.memberBankAccountId = '0'
        this.transactionObj.memberBankAccountName = bankAccountObj.memberBankAccountName
        this.transactionObj.memberBankAccountNumber = bankAccountObj.memberBankAccountNumber
        this.transactionObj.memberBankName = bankAccountObj.memberBankName
        this.transactionObj.memberBankProvince = bankAccountObj.memberBankProvince
        this.transactionObj.memberBankBranch = bankAccountObj.memberBankBranch
        this.transactionObj.memberBankCity = bankAccountObj.memberBankCity
        this.transactionObj.saveNewBank = bankAccountObj.saveNewBank
      } else {
        this.transactionObj.memberBankAccountId = 0
        this.transactionObj.memberBankAccountName = ''
        this.transactionObj.memberBankAccountNumber = ''
        this.transactionObj.memberBankName = ''
        this.transactionObj.memberBankProvince = ''
        this.transactionObj.memberBankBranch = ''
        this.transactionObj.memberBankCity = ''
        this.transactionObj.saveNewBank = ''
      }
      this.closeNewBankAccountDialog()
    },
    proceedToMemberDepositDetailTab() {
      if (this.$refs.localBankStepOneTransactionForm.validate()) this.localBankDepositStep = 2
    },
    backToPreviousStepDetailTab() {
      this.localBankDepositStep = 1
    },
    makeNewTransaction() {
      this.selectedBankCodeValue = ''
      this.merchanctBankAccountNumber = ''
      this.localBankDepositStep = 1
      this.transactionObj = {
        /* TRANSACTION INFO */
        transactionType: SHARED.DEPOSIT,
        methodCode: '',
        amount: 0,
        depositDateTime: '',
        attachmentFileType: '',
        attachment: '',
        ipAddress: '',
        saveNewBank: false,
        /* MEMBER INFO */
        memberCode: localStorage.getItem(SESSION.USERNAME),
        memberRealName: '',
        currency: localStorage.getItem(SESSION.CURRENCY),
        memberBankAccountId: '',
        memberBankAccountName: '',
        memberBankAccountNumber: '',
        memberBankName: '',
        memberBankProvince: '',
        memberBankBranch: '',
        memberBankCity: '',
        memberEmail: '',
        memberContactIdorNumber: '',
        /* MERCHANT or ONLINE GATEWAY INFO */
        merchantBankCode: '',
        gatewayAccountId: '',
        gatewayAccountBankId: '',
        /* OTHER */
        transactionSuccessUrl: uiHelper.getHostname(),
        oriCurrency: '',
        oriAmount: '',
        language: uiHelper.getLanguage(),
        platform: uiHelper.getPlatform()
      }
      this.bonusObj.id = ''
      this.submittedTransactionResponse = null
      this.transactionResponseResult = {
        success: false,
        complete: false,
        code: 0
      }
      this.getPaymentMethod()
      this.getMemberBankAccount()
      this.getAvailableBonus()
      this.getBank()
      this.qrCodeUrl = ''
    },
    checkMemberTokenValidity() {
      let requiredLoading = false
      this.$store.dispatch(MEMBER_CHECK_TOKEN_VALIDITY, {
        requiredLoading
      })
    },
    getPaymentMethod() {
      let obj = {
        memberCode: localStorage.getItem(SESSION.USERNAME),
        realName: this.memberInfo.name,
        currency: localStorage.getItem(SESSION.CURRENCY)
      }
      this.$store.dispatch(`${PAYMENT_AVAILABLE_METHOD}`, {
        obj
      })
    },
    getMerchantBankAccount() {
      let obj = {
        memberCode: localStorage.getItem(SESSION.USERNAME),
        currency: localStorage.getItem(SESSION.CURRENCY)
      }
      this.$store.dispatch(`${PAYMENT_MERCHANT_BANK_ACCOUNT}`, {
        obj
      })
    },
    getMemberBankAccount() {
      let obj = {
        memberCode: localStorage.getItem(SESSION.USERNAME)
      }
      this.$store.dispatch(`${PAYMENT_MEMBER_BANK_ACCOUNT}`, {
        obj
      })
    },
    getAvailableBonus() {
      this.$store.dispatch(`${BONUS_LIST}`)
    },
    getBonusDisplayText(e) {
      let bonusLocale = e.locale.find(x => x.language === this.currentLanguage)
      let percentageText = this.getPercentageText(e)
      let nominalText = ''
      if (bonusLocale) {
        nominalText = bonusLocale.title
      } else {
        let enLocale = e.locale.find(x => x.language === 'en')
        if (enLocale) {
          nominalText = enLocale.title
        } else {
          nominalText = e.name
        }
      }
      if (percentageText) {
        return `${nominalText} - ${percentageText}%`
      }
      return nominalText
    },
    getPercentageText(bonus) {
      if (bonus.formula != 'percentage') {
        return ''
      }
      if (!bonus.useReleaseTiers) {
        return bonus.percentGive
      }
      let currentMemberGroup = this.memberInfo.group
      let selectedApplyBonusMemberGroup = bonus.memberGroup.find(x => x.member_group.toLowerCase() == currentMemberGroup.toLowerCase())
      if (!selectedApplyBonusMemberGroup) {
        return bonus.percentGive
      }
      if (selectedApplyBonusMemberGroup.release_type != 2) {
        return ''
      }
      return parseFloat(selectedApplyBonusMemberGroup.percentage_given)
    },
    updateSelectedMemberBankAccount(data) {
      if (data != 0) {
        this.tempMemberBankAccountId = data
        let selectedMemberAccount = this.memberBankAccounts.find(x => x.id == data)
        this.transactionObj.memberBankAccountName = selectedMemberAccount.accountName
        this.transactionObj.memberBankAccountNumber = selectedMemberAccount.accountNumber
        this.transactionObj.memberBankName = selectedMemberAccount.bankName
      } else if (data == 0) {
        this.openNewBankAccountDialog()
      }
    },
    uploadDepositAttachement() {
      var form = this
      let file = this.transactionObj.rawAttachment
      if (file != null || file != undefined) {
        var reader = new FileReader()
        reader.onload = function(e) {
          let size = e.total
          var image = new Image()
          image.onload = function() {
            // Resize the image using canvas
            if (size > 2000000) {
              form.maximumImageSizeDialog()
              form.$refs.fileimage.reset()
            }
            var canvas = document.createElement('canvas'),
              max_size = 700, // TODO : max size for a pic
              width = image.width,
              height = image.height
            if (width > height) {
              if (width > max_size) {
                height *= max_size / width
                width = max_size
              }
            } else {
              if (height > max_size) {
                width *= max_size / height
                height = max_size
              }
            }
            canvas.width = width
            canvas.height = height
            canvas.getContext('2d').drawImage(image, 0, 0, width, height)
            //Getting base64 string;
            /*var dataUrl = canvas.toDataURL('image/jpeg')
            form.processBase64String(dataUrl)*/
          }
          // this will trigger image.onload
          image.src = e.target.result
        }
        reader.readAsDataURL(file)
      }
    },
    /*processBase64String(base64String) {
      if (base64String != null) {
        var base64BreakPoint = base64String.indexOf(',')
        this.transactionObj.attachment = base64String.slice(base64BreakPoint + 1)
        this.transactionObj.attachmentFileType = base64String.substr(0, base64BreakPoint)
      }
    },*/
    openNewBankAccountDialog() {
      if (this.transactionObj.memberBankAccountId == '0') {
        this.bankAccountDetail = {
          memberBankAccountId: this.transactionObj.memberBankAccountId,
          memberBankAccountName: this.transactionObj.memberBankAccountName,
          memberBankAccountNumber: '',
          memberBankName: this.transactionObj.memberBankName,
          memberBankProvince: this.transactionObj.memberBankProvince,
          memberBankBranch: this.transactionObj.memberBankBranch,
          memberBankCity: this.transactionObj.memberBankCity,
          saveNewBank: this.transactionObj.saveNewBank,
          memberCode: this.transactionObj.memberCode,
          isUpdateBank: false
        }
      } else {
        this.bankAccountDetail = null
        this.bankAccountDetail = {
          memberBankAccountNumber: '',
          memberBankAccountName: this.transactionObj.memberBankAccountName
        }
      }
      this.newBankAccountDialogShow = true
    },
    closeNewBankAccountDialog() {
      this.transactionObj.memberBankAccountId = this.tempMemberBankAccountId
      this.newBankAccountDialogShow = false
    },
    submitTransaction() {
      if (this.$refs.transactionForm.validate() && this.validateBonusForm()) {
        let obj = this.transactionObj
        if (obj.merchantBankCode == '0') {
          obj.merchantBankCode = `${this.selectedBankCodeValue}${this.merchanctBankAccountNumber}`
        }
        if (obj.methodCode != 'NLB') {
          obj.memberBankAccountNumber = this.memberInfo.mobile.value
        }
        obj.memberRealName = this.memberInfo.name
        obj.transactionSuccessUrl = uiHelper.getHostname() + `/bank/deposit-success`
        this.$store.dispatch(`${PAYMENT_SUBMIT_PAYMENT_TRANSACTION}`, {
          obj
        })
      }
    },
    memberBankAccountDisplayText(data) {
      if (data.value == 0) {
        return locale.getMessage(`label.${data.bankName}`)
      } else {
        return data.bankName
      }
    },
    getGatewaySupportedBank() {
      let obj = {
        memberCode: localStorage.getItem(SESSION.USERNAME)
      }
      this.$store.dispatch(`${PAYMENT_ONLINE_PAYMENT_SUPPORTED_BANK}`, {
        obj
      })
    },
    viewTransactionHistory() {
      this.$router.push({
        name: ROUTE_NAME.TRANSACTION,
        params: {
          tab: 'deposit'
        }
      })
    },
    routeToHome() {
      this.$router.push({
        name: ROUTE_NAME.HOME
      })
    },
    onCloseClick() {
      this.$store.dispatch(`${BONUS_RESET_APPLY_BONUS_RESPONSE_STORE}`)
      this.msgDialog.show = false
    },
    showConfirmationDialog() {
      let memberGroup = this.memberInfo.group
      let selectedApplyBonus = this.bonusList.find(x => this.bonusObj.id && x.id == this.bonusObj.id)
      if (selectedApplyBonus) {
        let selectedApplyBonusMemberGroup = selectedApplyBonus.memberGroup.find(x => x.member_group.toLowerCase() == memberGroup.toLowerCase())
        if (selectedApplyBonusMemberGroup != null) {
          if (selectedApplyBonus.formula == 'fixed') {
            this.bonusAmount = parseFloat(selectedApplyBonusMemberGroup.amount_given)
          } else {
            var calculatedAmount = (parseFloat(this.transactionObj.amount) * parseFloat(selectedApplyBonusMemberGroup.percentage_given)) / 100
            var maxAmount = selectedApplyBonusMemberGroup.maxAmount
            if (maxAmount) {
              this.bonusAmount = Math.min(maxAmount, calculatedAmount)
            } else {
              this.bonusAmount = calculatedAmount
            }
          }
        } else {
          if (selectedApplyBonus.formula == 'fixed') {
            this.bonusAmount = parseFloat(selectedApplyBonus.amountGive)
          } else {
            var calculatedAmount = (parseFloat(this.transactionObj.amount) * parseFloat(selectedApplyBonus.percentGive)) / 100
            var maxAmount = selectedApplyBonus.maxAmount
            if (maxAmount) {
              this.bonusAmount = Math.min(calculatedAmount, maxAmount)
            } else {
              this.bonusAmount = calculatedAmount
            }
          }
        }
        this.bonusTurnOver = (parseFloat(this.bonusAmount) + parseFloat(this.transactionObj.amount)) * parseFloat(selectedApplyBonus.turnoverMultiplier)
      } else {
        this.bonusTurnOver = parseFloat(this.transactionObj.amount)
      }
      this.msgDialog.show = true
    },
    openAlert(success, msg) {
      this.alertSuccess = success
      this.alertMessage = msg
      this.alertShow = true
    },
    copyClipboard(copyText) {
      navigator.clipboard.writeText(copyText)
    }
  }
}
</script>

<style lang="scss">
.percent-label {
  top: -0.9rem;
  left: 53px;
  font-size: 14px;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  margin: -1px;
  position: absolute;
  font-family: 'Bebas Neue', Helvetica, Tahoma, sans-serif;

  &.gateway-label {
    left: 58px;
  }
}
.funds-container {
  max-width: 1240px;
  margin: auto;

  .funds-wrapper {
    max-width: 744px;
    margin: auto;
    background: var(--v-background-base);

    .v-stepper {
      border-radius: 0px !important;
    }

    .funds-input {
      padding-right: 12px;
      font-size: 14px;

      .v-text-field__prefix {
        color: #30795a;
      }
    }

    .v-text-field--outlined .v-label {
      top: unset;
    }

    .funds-divider {
      width: 100%;
      height: 1px;
      background-color: var(--v-divider-base);
      margin: 2px 0px;
    }

    .gateway-button {
      border: 1px solid var(--v-background-base);
      //background: rgba(255, 255, 255, 0.1);
      background-color: var(--v-background-base);
      padding: 10px;
      min-width: 100px;

      .v-btn__content {
        display: flex;
        flex-direction: column;

        img {
          width: 24px;
          height: 24px;
        }

        label {
          cursor: pointer;
          text-transform: capitalize;
        }
      }

      .channelBtnImg {
        min-width: 70px;
      }
    }

    .active-gateway-button {
      &.v-btn--active {
        background-color: var(--v-primary-base);
        border: 2px solid var(--v-primary-base);

        .method_payment,
        .gateway_name,
        .amount_cls {
          color: white;
        }
      }
    }

    label.gateway_name.gateway-font {
      font-size: smaller;
    }

    label.gateway_name.gateway-font-mobile {
      font-size: small;
    }

    .section-container {
      position: relative;
      margin-top: 12px;
      padding: 12px;
      background: var(--v-background-lighten1);

      .funds-title-wrapper {
        position: relative;
        padding-left: 12px;
        margin-bottom: 8px;

        .funds-title::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 4px;
          height: 100%;
          background-color: var(--v-primary-base);
        }
      }

      .button-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 12px;
        margin-top: 8px;
      }

      .button-container-mobile {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 12px;
        margin-top: 8px;
        overflow: scroll;
      }
    }

    .deposit-button {
      width: 100%;
      background-color: var(--v-primary-base);
      border-radius: 4px;
      color: var(--v-text_reverse-base);
    }

    .funds-button {
      background: var(--v-primary-base);
      border-radius: 4px;
      width: 100%;
    }

    .back-button {
      border: 1px solid var(--v-text-base);
      background: var(--v-background-base);
      border-radius: 4px;
      width: 100%;
      height: 100%;
    }

    .next-button {
      background: var(--v-background_2-base);
      border-radius: 4px;
      width: 100%;
      color: var(--v-text_reverse-base);
    }

    .credit-card-wrapper {
      background-color: var(--v-primary-base);
      padding: 12px;
      display: flex;
      justify-content: center;

      .credit-card {
        position: relative;
        width: 100%;
        max-width: 360px;
        padding: 24px;
        color: var(--v-text_reverse-base);
        border-radius: 12px;
        background: url('/static/image/credit-card/bg-card.png') no-repeat center/cover, linear-gradient(240deg, #3356cd, #86bff4);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
        font-size: 14px;

        .chip {
          position: absolute;
          top: 12px;
          right: 12px;
        }

        .credit-card-number {
          font-size: 24px;
        }

        .credit-card-amount {
          color: yellow;
          font-size: 20px;
        }
      }
    }
  }
}

.deposit-tab {
  width: 150px;
  margin-right: 16px;
  padding: 10px;
  background-color: #fff;
  text-transform: capitalize;
}

.deposit-tab-mobile {
  width: 120px;
  margin-right: 10px;
  padding: 5px;
  background-color: #fff;
  text-transform: capitalize;
}

.card_container {
  margin-top: 70px;
  border-radius: 20px !important;

  &.nonBanking {
    margin-top: 0;
  }
}

.payment-dialog-title {
  color: #acacac;
}

.pay-info-group {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: left;
}

.pay-info-group .bank-card.card-blue {
  background: url('/static/image/credit-card/bg-card.png') no-repeat center/cover, linear-gradient(240deg, #3356cd, #86bff4);
}

.pay-info-group .bank-card-mobile.card-blue {
  background: url('/static/image/credit-card/bg-card.png') no-repeat center/cover, linear-gradient(240deg, #3356cd, #86bff4);
}

.pay-info-group .bank-card {
  padding: 1.25rem 0;
  display: inline-block;
  vertical-align: middle;
}

.bank-card {
  position: relative;
  width: 22.3125rem;
  height: 190px;
  padding: 1.25rem;
  border-radius: 0.9375rem;
  background-size: cover;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  color: #fff;
}

.bank-card-mobile {
  position: relative;
  width: 22.3125rem;
  height: 11rem;
  padding: 1.25rem;
  border-radius: 0.9375rem;
  background-size: cover;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  color: #fff;
}

.bank-card::before {
  content: '';
  position: absolute;
  z-index: 1;
  top: 10px;
  right: 10px;
  width: 45px;
  height: 40px;
  background: url('/static/image/icon/wallet_chip.png') no-repeat top right/contain;
}

.bank-card-mobile::before {
  content: '';
  position: absolute;
  z-index: 1;
  top: 15px;
  right: 3%;
  width: 30px;
  height: 30px;
  background: url('/static/image/icon/wallet_chip.png') no-repeat top right/contain;
}

.bank-name {
  margin: 0px;
  padding-top: 0px;
}

.pay-info-group .bank-account {
  margin-bottom: 0.9375rem;
  padding-top: 3px;
}

.pay-info-group .bank-account-mobile {
  padding-top: 3px;
}

.bank-info-copy {
  position: relative;
  padding: 0 0.9375rem;
  padding-left: 12px;
  cursor: pointer;
}

.bank-name .bank-info-copy p,
.bank-name .bank-info-copy span {
  display: block;
  width: 70%;
  overflow: hidden;
  line-height: 30px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pay-info-group .bank-account p {
  font-size: 25px;
}

.pay-info-group .bank-account-mobile p {
  font-size: 20px;
}

.bank-member p,
.bank-account p {
  width: 90%;
}

.bank-member p,
.bank-account-mobile p {
  width: 90%;
}

.bank-info-copy p,
.bank-info-copy span {
  line-height: 35px;
}

.bank-info-copy p {
  text-shadow: 0 0 2px rgb(0 0 0 / 50%);
}

.display-box {
  display: -webkit-box;
}

.bank-code-mobile {
  flex-wrap: nowrap;
  overflow-scrolling: touch;
  overflow: auto;
}

.bank-code-mobile-button {
  margin-right: 5px;
}

.point.icon-color {
  color: whitesmoke;
}

@media (max-width: 599px) {
  .card_container {
    margin-top: 20px;
    border-radius: 20px !important;
  }
}

.icon_normal_pn {
  width: 38px;
  fill: #fff;
  color: #000;
  top: -35px;
  position: absolute;
  right: -29px;
}

.qrCode {
  img {
    margin: auto;
  }
}

.tag-recommond {
  position: absolute;
  top: -0.28rem;
  left: -0.7125rem;
  width: 1.25rem;
  height: 0.9375rem;
  border-radius: 0 0.1875rem 0.1875rem 0;
  background-color: #f56909;
  box-shadow: 0 0 2px rgb(0 0 0 / 30%);
}

.tag-recommond::before,
.tag-recommond::after {
  content: '';
}

.tag-recommond::before {
  position: absolute;
  z-index: 1;
  bottom: -4px;
  left: 0;
  width: 0;
  height: 0;
  border-width: 0 4px 4px 0;
  border-style: solid;
  border-color: transparent #112507 transparent transparent;
}

.tag-recommond::after {
  position: absolute;
  z-index: 2;
  top: -0.0625rem;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  -webkit-mask: url('/static/image/payment/icon-recommond.svg') no-repeat center/55%;
  mask: url('/static/image/payment/icon-recommond.svg') no-repeat center/55%;
}
</style>
